// components/UniqueLeads.tsx
import React, { useState } from 'react';
import { Input, Button, Table, Alert, Space } from 'antd';
import axios from '../api/axiosInstance';

interface Offer {
  offer: string;
  campaign_group: string;
  status: string;
}

interface LeadData {
  tester: string;
  ip: string;
  group: string;
  offers: Offer[];
}

const UniqueLeads: React.FC = () => {
  const [tester, setTester] = useState<string>('');
  const [leads, setLeads] = useState<LeadData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleCheck = async () => {
    if (!tester.trim()) {
      setError('Будь ласка, введіть значення tester');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.post('/api/unique-leads', { tester });
      setLeads(response.data);
    } catch (err: any) {
      setError(err.response?.data?.error || 'Невідома помилка');
    } finally {
      setLoading(false);
    }
  };

  const leadColumns = [
    {
      title: 'Тестер',
      dataIndex: 'tester',
      key: 'tester',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Менеджер',
      dataIndex: 'group',
      key: 'group',
      render: (text: string) => (
        <>
          {text.split(', ').map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
        </>
      ),
    },
  ];

  const offerColumns = [
    {
      title: 'Продукт',
      dataIndex: 'offer',
      key: 'offer',
    },
    {
      title: 'Менеджер',
      dataIndex: 'campaign_group',
      key: 'campaign_group',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <span style={{ color: status === 'sale' ? 'green' : 'blue' }}>{status}</span>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Space style={{ marginBottom: '20px' }}>
        <Input
          placeholder="Введіть tester"
          value={tester}
          onChange={(e) => setTester(e.target.value.trim())}
          style={{ width: '300px' }}
        />
        <Button type="primary" onClick={handleCheck} loading={loading}>
          Перевірити
        </Button>
      </Space>

      {error && (
        <Alert
          message="Помилка"
          description={error}
          type="error"
          showIcon
          closable
          style={{ marginBottom: '20px' }}
          onClose={() => setError('')}
        />
      )}

      {!loading && leads.length > 0 && (
        <Table
          dataSource={leads}
          columns={leadColumns}
          rowKey={(record) => record.tester}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                dataSource={record.offers}
                columns={offerColumns}
                rowKey={(offerRecord) => offerRecord.offer}
                pagination={false}
              />
            ),
          }}
          pagination={false}
        />
      )}
    </div>
  );
};

export default UniqueLeads;
