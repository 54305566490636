import React, { useState, useEffect, useCallback } from 'react';
import { Table, Typography, Button, notification, Input, DatePicker } from 'antd';
import axios from '../api/axiosInstance';

const { Title } = Typography;
const { RangePicker } = DatePicker;

interface Log {
  _id: string;
  timestamp: string;
  level: string;
  message: string;
  metadata: {
    url: string;
    method: string;
    statusCode: number;
    response?: string;
  };
}

const LogsPage: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({ startDate: '', endDate: '', searchText: '' });

  const fetchLogs = useCallback(
    async (lastTimestamp?: string) => {
      setLoading(true);
      try {
        const { data } = await axios.get<Log[]>('/api/logs', {
          params: { ...filters, lastTimestamp },
        });

        if (data.length < 20) {
          setHasMore(false);
        }

        setLogs((prevLogs) => [...prevLogs, ...data]);
      } catch (error) {
        console.error('Помилка отримання логів:', error);
        notification.error({ message: 'Не вдалося отримати логи.' });
      } finally {
        setLoading(false);
      }
    },
    [filters] // Залежність: зміни у фільтрах
  );

  const deleteAllLogs = async () => {
    try {
      await axios.delete('/api/logs', { params: filters });
      setLogs([]);
      setHasMore(false);
      notification.success({ message: 'Усі вибрані логи успішно видалено.' });
    } catch (error) {
      console.error('Помилка видалення логів:', error);
      notification.error({ message: 'Не вдалося видалити логи.' });
    }
  };

  const applyFilters = () => {
    setLogs([]);
    setHasMore(true);
    fetchLogs();
  };

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);;

  const loadMore = () => {
    const lastLog = logs[logs.length - 1];
    const lastTimestamp = lastLog?.timestamp;
    if (lastTimestamp) {
      fetchLogs(lastTimestamp);
    }
  };

  const handleDateChange = (dates: any) => {
    if (dates) {
      const [start, end] = dates;
      setFilters((prev) => ({
        ...prev,
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        startDate: '',
        endDate: '',
      }));
    }
  };

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Рівень',
      dataIndex: 'level',
      key: 'level',
      render: (level: string) => (
        <span style={{ color: level === 'error' ? 'red' : 'black' }}>{level}</span>
      ),
    },
    {
      title: 'Повідомлення',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Метадані',
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata: any) => (
        <div>
          <p><b>URL:</b> {metadata.url}</p>
          <p><b>Метод:</b> {metadata.method}</p>
          <p><b>Код статусу:</b> {metadata.statusCode}</p>
          {metadata.response && <p><b>Відповідь:</b> {metadata.response}</p>}
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Title level={2}>Логи системи</Title>
      <div style={{ marginBottom: '20px' }}>
        <Input
          placeholder="Пошук за URL чи відповіддю"
          onChange={(e) => setFilters((prev) => ({ ...prev, searchText: e.target.value }))}
          style={{ width: '300px', marginRight: '10px' }}
        />
        <RangePicker
          onChange={handleDateChange}
          style={{ marginRight: '10px' }}
        />
        <Button type="primary" onClick={applyFilters}>
          Застосувати фільтри
        </Button>
        <Button type="primary" danger onClick={deleteAllLogs} style={{ marginLeft: '10px' }}>
          Видалити всі логи
        </Button>
      </div>
      <Table
        dataSource={logs.map((log) => ({ ...log, key: log._id }))}
        columns={columns}
        loading={loading}
        pagination={false}
      />
      {hasMore && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button type="primary" onClick={loadMore} loading={loading} disabled={loading}>
            Завантажити більше
          </Button>
        </div>
      )}
    </div>
  );
};

export default LogsPage;
