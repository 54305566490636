// socket.ts
import { io, Socket } from "socket.io-client";
import { getToken } from './utils/auth';
const API_URL = process.env.REACT_APP_KEITARO_API_URL;

const token = getToken();

const socket: Socket = io(API_URL, {
    auth: {
      token: token, // Передаємо токен
    },
  });

export default socket;
