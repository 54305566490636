import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, Popconfirm, Space } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../api/axiosInstance';

const BundlesTable: React.FC = () => {
  const [bundles, setBundles] = useState<any[]>([]);
  const [sources, setSources] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedBundle, setSelectedBundle] = useState<any | null>(null);
  const [selectedSource, setSelectedSource] = useState<any | null>(null);
  const [countries, setCountries] = useState<string[]>([]);
  const [form] = Form.useForm();

  const [filterQuery, setFilterQuery] = useState<string>('');
  const [filterGroupId, setFilterGroupId] = useState<string | undefined>(undefined);
  const [filterSourceId, setFilterSourceId] = useState<string | undefined>(undefined);
  const [filterCountry, setFilterCountry] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchBundles();
    fetchSources();
    fetchGroups();
    fetchTeams();
    fetchCountries();
  }, []);

  const fetchBundles = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/bundles');
      setBundles(response.data);
    } catch (error) {
      message.error('Не вдалося отримати зв\'язки');
    } finally {
      setLoading(false);
    }
  };

  const fetchSources = async () => {
    try {
      const sourcesResponse = await axios.get('/api/sources'); // Отримуємо список джерел
      setSources(sourcesResponse.data);
    } catch (error) {
      message.error('Не вдалося отримати джерела');
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get('/api/product-groups');
      setGroups(response.data);
    } catch (error) {
      message.error('Не вдалося отримати групи продуктів');
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('/api/offers/countries');
      setCountries(response.data.countries);
    } catch (error) {
      message.error('Не вдалось отримати країни');
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get('/api/teams');
      setTeams(response.data);
    } catch (error) {
      message.error('Не вдалося отримати команди');
    }
  };

  // Відкрити модальне вікно для додавання або оновлення
  const openModal = (bundle?: any) => {
    if (bundle) {
      setSelectedBundle(bundle);
      handleSourceChange(bundle.sourceId)
      form.setFieldsValue({ link: bundle.link, sourceId: bundle.sourceId, groupId: bundle.groupId, country: bundle.country });
    } else {
      setSelectedBundle(null);
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  // Закрити модальне вікно
  const closeModal = () => {
    setIsModalVisible(false);
  };

  // Додати або оновити зв'язку
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (selectedBundle) {
        await axios.put(`/api/bundles/${selectedBundle.id}`, values);
        message.success('Зв\'язку успішно оновлено');
      } else {
        await axios.post('/api/bundles', values);
        message.success('Зв\'язку успішно додано');
      }
      fetchBundles();
      closeModal();
    } catch (error) {
      message.error('Не вдалося зберегти зв\'язку');
    }
  };

  // Видалити зв'язку
  const handleDelete = async (bundleId: string) => {
    try {
      await axios.delete(`/api/bundles/${bundleId}`);
      message.success('Зв\'язку успішно видалено');
      fetchBundles();
    } catch (error) {
      message.error('Не вдалося видалити зв\'язку');
    }
  };

  // Вибір джерела і зміна опцій групи в залежності від його "унікальності"
  const handleSourceChange = (sourceId: string) => {
    const source = sources.find((s) => s.sourceId === sourceId);
    setSelectedSource(source);
    if (source?.uniqueFor === 1) {
      fetchGroups();
      form.setFieldsValue({ groupId: undefined });  // Очистити поле групи
    } else if (source?.uniqueFor === 2) {
      fetchTeams();
      form.setFieldsValue({ groupId: undefined });  // Очистити поле групи
    } else if (source?.uniqueFor === 3) {
      form.setFieldsValue({ groupId: '-' });  // Встановити значення "-" для групи
    }
  };

  const getSourceName = useCallback((sourceId: string): string => {
    const source = sources.find((src) => src.sourceId === sourceId);
    return source ? source.name : '';
  }, [sources]);

  const getGroupName = useCallback((groupId: string): string => {
    if (groupId === '-') return '-';
    const group = groups.find((grp) => grp.id === groupId) || teams.find((team) => team.id === groupId);
    return group ? group.name : '';
  }, [groups, teams]);

  const filteredData = useMemo(() => {
    return bundles.filter((record) => {
      const { id, link, sourceId, groupId, country } = record;

      // Перевірка загального текстового фільтра
      const normalizedQuery = filterQuery.toLowerCase().trim();
      const sourceName = getSourceName(sourceId).toLowerCase();
      const groupName = getGroupName(groupId).toLowerCase();
      const recordText = [
        id?.toString() || '',
        link || '',
        sourceName,
        groupName,
        country || ''
      ].map(v => v.toLowerCase());

      const matchesQuery = normalizedQuery === ''
        || recordText.some(field => field.includes(normalizedQuery));

      // Перевірка фільтру за групою
      const matchesGroup = !filterGroupId || filterGroupId === groupId;

      // Перевірка фільтру за джерелом
      const matchesSource = !filterSourceId || filterSourceId === sourceId;

      // Перевірка фільтру за країною
      const matchesCountry =
        filterCountry === undefined
          ? true // Якщо фільтр країни не застосовано, пропускаємо всі записи
          : filterCountry === ''
            // Якщо обрано "без країни", перевіряємо чи поле country порожнє або не задане
            ? (!country || country.trim() === '')
            // Інакше порівнюємо з конкретною країною
            : country === filterCountry;

      return matchesQuery && matchesGroup && matchesSource && matchesCountry;
    });
  }, [bundles, filterQuery, filterGroupId, filterSourceId, filterCountry, getSourceName, getGroupName]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Посилання',
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: 'Джерело',
      dataIndex: 'sourceId',
      key: 'sourceId',
      render: (sourceId: string) => {
        const source = sources.find((src) => src.sourceId === sourceId);
        return source ? source.name : 'Невідоме джерело';
      },
    },
    {
      title: 'Група',
      dataIndex: 'groupId',
      key: 'groupId',
      render: (groupId: string) => {
        if (groupId === '-') return '-';
        const group = groups.find((grp) => grp.id === groupId) || teams.find((team) => team.id === groupId);
        return group ? group.name : 'Невідома група/команда';
      },
    },
    {
      title: 'Країна',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => openModal(record)} style={{ marginRight: '8px' }} />
          <Popconfirm
            title="Ви впевнені, що хочете видалити зв'язку?"
            onConfirm={() => handleDelete(record.id)}
            okText="Так"
            cancelText="Ні"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: '16px' }} wrap>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => openModal()}
        >
          Додати зв'язку
        </Button>

        {/* Фільтр по тексту */}
        <Input
          placeholder="Пошук по будь-якій колонці"
          value={filterQuery}
          onChange={(e) => setFilterQuery(e.target.value)}
          style={{ width: 200 }}
        />

        {/* Фільтр по групі */}
        <Select
          showSearch
          allowClear
          placeholder="Фільтр по групі"
          value={filterGroupId}
          onChange={(value) => setFilterGroupId(value)}
          style={{ width: 200 }}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={[
            ...groups.map((group) => ({
              label: group.name,
              value: group.id
            })),
            ...teams.map((team) => ({
              label: team.name,
              value: team.id
            })),
            { label: '-', value: '-' }
          ]}
        />

        {/* Фільтр по джерелу */}
        <Select
          showSearch
          allowClear
          placeholder="Фільтр по джерелу"
          value={filterSourceId}
          onChange={(value) => setFilterSourceId(value)}
          style={{ width: 200 }}
          filterOption={(input, option) =>
            ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={sources.map((source) => ({
            label: source.name,
            value: source.sourceId
          }))}
        />

        {/* Фільтр по країні */}
        <Select
          showSearch
          allowClear
          placeholder="Фільтр по країні"
          value={filterCountry}
          onChange={(value) => setFilterCountry(value)}
          style={{ width: 200 }}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'Без країни', value: '' },  // Опція для пустого значення
            ...countries.map((c) => ({ label: c, value: c }))
          ]}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
            setCurrentPage(1); // Повертаємося на першу сторінку при зміні розміру
          },
          onChange: (page) => setCurrentPage(page),
        }}
      />
      <Modal
        title={selectedBundle ? 'Оновити зв\'язку' : 'Додати зв\'язку'}
        visible={isModalVisible}
        onCancel={closeModal}
        onOk={handleSubmit}
        okText={selectedBundle ? 'Оновити' : 'Додати'}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="link"
            label="Посилання"
            rules={[{ required: true, message: 'Будь ласка, введіть посилання' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="sourceId"
            label="Джерело"
            rules={[{ required: true, message: 'Будь ласка, виберіть джерело' }]}
          >
            <Select
              showSearch
              placeholder="Оберіть джерело"
              onChange={handleSourceChange}
              filterOption={(input, option) =>
                ((option?.label ?? '')).toLowerCase().includes(input.toLowerCase())
              }
              options={sources.map((source) => ({
                label: source.name, // Виводимо назву групи
                value: source.sourceId,   // Використовуємо id для збереження
              }))}
            />
          </Form.Item>
          <Form.Item
            name="groupId"
            label="Група"
            rules={[{ required: form.getFieldValue('groupId') !== '-', message: 'Будь ласка, виберіть групу' }]}
          >
            <Select
              showSearch
              placeholder="Оберіть групу"
              value={selectedSource?.uniqueFor === 3 ? '-' : form.getFieldValue('groupId')} // Якщо uniqueFor = 3, вибираємо "-"
              options={
                selectedSource?.uniqueFor === 1
                  ? groups.map((group) => ({
                    label: group.name,  // Виводимо назву групи продуктів
                    value: group.id,    // Використовуємо id групи продуктів для збереження
                  }))
                  : selectedSource?.uniqueFor === 2
                    ? teams.map((team) => ({
                      label: team.name,   // Виводимо назву команди
                      value: team.id,     // Використовуємо id команди для збереження
                    }))
                    : []  // Порожній список для uniqueFor === 3 (1 на все)
              }
            />
          </Form.Item>
          <Form.Item
            name="country"
            label="Країна"
          >
            <Select
              showSearch
              allowClear
              placeholder="Виберіть країну або залиште пустою"
              options={countries.map((country) => ({
                label: country,
                value: country
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BundlesTable;
