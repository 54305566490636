import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Button, notification } from 'antd';
import axios from '../api/axiosInstance';

interface Settings {
  keitaroReqDelay: number;
  keycrmReqDelay: number;
}

const SettingsPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  

  const saveSettings = async (values: Settings) => {
    setLoading(true);
    try {
      // Приводимо всі значення до типу `string`
      const formattedValues = Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = String(value);
        return acc;
      }, {} as Record<string, string>);

      await axios.post('/api/settings/update', formattedValues);
      notification.success({ message: 'Settings updated successfully.' });
    } catch (error) {
      console.error('Error updating settings:', error);
      notification.error({ message: 'Failed to update settings.' });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values: Settings) => {
    saveSettings(values);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await axios.get<Settings>('/api/settings');
        form.setFieldsValue(data);
      } catch (error) {
        console.error('Error fetching settings:', error);
        notification.error({ message: 'Failed to fetch settings.' });
      }
    };
    
    fetchSettings();
  }, [form]);

  return (
    <div style={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}>
      <h2>Settings</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Keitaro Queue Delay (ms)"
          name="keitaroReqDelay"
          rules={[{ required: true, message: 'Please input the Keitaro Queue Delay!' }]}
        >
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="KeyCRM Queue Delay (ms)"
          name="keycrmReqDelay"
          rules={[{ required: true, message: 'Please input the KeyCRM Queue Delay!' }]}
        >
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Keitaro parallel request limit"
          name="keitaroParallelLimit"
          rules={[{ required: true, message: 'Please input the K  eitaro parallel request limit!' }]}
        >
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingsPage;