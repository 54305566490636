import React, { useState, useEffect, useMemo } from 'react';
import { Table, Checkbox, Button, message, Select, Input, DatePicker, Space } from 'antd';
import axios from '../api/axiosInstance';
import dayjs from 'dayjs';

const OffersTable: React.FC = () => {
  const [offers, setOffers] = useState<any[]>([]);
  const [modifiedOffers, setModifiedOffers] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]); // Для груп продуктів
  const [sources, setSources] = useState<any[]>([]); // Для джерел
  const [countries, setCountries] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filterOfferId, setFilterOfferId] = useState<string>('');
  const [filterName, setFilterName] = useState<string>('');
  const [filterGroupId, setFilterGroupId] = useState<number | undefined>(undefined);
  const [filterAllowedSources, setFilterAllowedSources] = useState<number[] | undefined>(undefined);
  const [filterIsActive, setFilterIsActive] = useState<boolean | undefined>(undefined);
  const [filterCountry, setFilterCountry] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchOffers();
    fetchGroups();
    fetchSources();
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get('/api/offers/countries');
      setCountries(response.data.countries);
    } catch (error) {
      message.error('Не вдалося отримати список країн');
    }
  };

  // Функція для отримання офферів і активних даних (offerGroupId, isActive)
  const fetchOffers = async () => {
    setLoading(true);
    try {
      const offersResponse = await axios.get('/api/offers/');
      setOffers(offersResponse.data);
    } catch (error) {
      message.error('Не вдалося отримати список офферів');
    } finally {
      setLoading(false);
    }
  };

  // Функція для отримання груп продуктів
  const fetchGroups = async () => {
    try {
      const response = await axios.get('/api/product-groups');
      setGroups(response.data);
    } catch (error) {
      message.error('Не вдалося отримати групи продуктів');
    }
  };

  // Функція для отримання джерел
  const fetchSources = async () => {
    try {
      const response = await axios.get('/api/sources');
      setSources(response.data);
    } catch (error) {
      message.error('Не вдалося отримати список джерел');
    }
  };

  // Оновлення активності оффера
  const handleCheckboxChange = (offerId: number, checked: boolean) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, isActive: checked } : offer
      )
    );

    // Оновлюємо модифіковані оффери
    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, isActive: checked } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, isActive: checked }];
      }
    });

    setIsSaveDisabled(false);
  };

  const handleRedepCheckboxChange = (offerId: number, checked: boolean) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, onlyRedep: checked } : offer
      )
    );

    // Оновлюємо модифіковані оффери
    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, onlyRedep: checked } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, onlyRedep: checked }];
      }
    });

    setIsSaveDisabled(false);
  };

  const handleCommentChange = (offerId: number, comment: string) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, comment: comment } : offer
      )
    );

    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, comment: comment } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, comment: comment }];
      }
    });

    setIsSaveDisabled(false);
  }

  const handleCapCountChange = (offerId: number, capCount: string) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, capCount: capCount } : offer
      )
    );

    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, capCount: capCount } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, capCount: capCount }];
      }
    });

    setIsSaveDisabled(false);
  }

  const handleCapDateChange = (offerId: number, capDate: Date | null) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, capDate: capDate } : offer
      )
    );

    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, capDate: capDate } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, capDate: capDate }];
      }
    });

    setIsSaveDisabled(false);
  }

  // Оновлення групи продуктів
  const handleGroupChange = (offerId: number, groupId: number) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, offerGroupId: groupId } : offer
      )
    );

    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, offerGroupId: groupId } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, offerGroupId: groupId }];
      }
    });

    setIsSaveDisabled(false);
  };

  // Оновлення дозволених джерел
  const handleSourceChange = (offerId: number, selectedSources: number[]) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.offerId === offerId ? { ...offer, allowedSourceId: selectedSources } : offer
      )
    );

    setModifiedOffers((prevModifiedOffers) => {
      const existingOffer = prevModifiedOffers.find((offer) => offer.offerId === offerId);
      if (existingOffer) {
        return prevModifiedOffers.map((offer) =>
          offer.offerId === offerId ? { ...offer, allowedSourceId: selectedSources } : offer
        );
      } else {
        return [...prevModifiedOffers, { offerId, allowedSourceId: selectedSources }];
      }
    });

    setIsSaveDisabled(false);
  };

  // Збереження змін
  const handleSave = async () => {
    try {
      await axios.post('/api/offers/', { offers: modifiedOffers });
      message.success('Оффери успішно оновлено');
      setModifiedOffers([]);
      setIsSaveDisabled(true);
      fetchOffers(); // Оновлюємо список офферів після збереження
    } catch (error) {
      message.error('Не вдалося зберегти оффери');
    }
  };

  const handleRefresh = async () => {
    await axios.post('/api/offers/refresh');
    fetchOffers();
    setModifiedOffers([]);
    setIsSaveDisabled(true);
  };

  const filteredOffers = useMemo(() => {
    return offers.filter((offer) => {
      // Фільтр по ID - якщо введено значення і воно число, фільтруємо за точною відповідністю
      if (filterOfferId.trim() !== '') {
        const idNumber = Number(filterOfferId.trim());
        if (isNaN(idNumber) || offer.offerId !== idNumber) {
          return false;
        }
      }

      // Фільтр по назві - частковий пошук у нижньому регістрі
      if (filterName.trim() !== '') {
        const nameLower = offer.name?.toLowerCase() || '';
        if (!nameLower.includes(filterName.toLowerCase().trim())) {
          return false;
        }
      }

      // Фільтр по групі
      if (filterGroupId !== undefined && offer.offerGroupId !== filterGroupId) {
        return false;
      }

      // Фільтр по дозволеним джерелам
      // Перевіряємо чи в offer.allowedSourceId є принаймні один зі списку вибраних джерел
      if (filterAllowedSources && filterAllowedSources.length > 0) {
        const allowed = offer.allowedSourceId || [];
        // Якщо хоч одне джерело з фільтра є в allowed, пропускаємо
        const intersection = allowed.filter((src: number) => filterAllowedSources.includes(src));
        if (intersection.length === 0) {
          return false;
        }
      }

      // Фільтр по активності
      // Якщо filterIsActive не undefined, то порівнюємо offer.isActive з ним
      if (filterIsActive !== undefined && offer.isActive !== filterIsActive) {
        return false;
      }

      // Фільтр по країні
      if (filterCountry !== undefined && filterCountry !== '') {
        // Якщо обрано конкретну країну, перевіряємо відповідність
        if (offer.country !== filterCountry) {
          return false;
        }
      } else if (filterCountry === '') {
        // Якщо обрано "без країни" (порожній рядок), показуємо записи де country порожнє або не задане
        if (offer.country && offer.country.trim() !== '') {
          return false;
        }
      }

      return true;
    });
  }, [offers, filterOfferId, filterName, filterGroupId, filterAllowedSources, filterIsActive, filterCountry]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'offerId',
      key: 'offerId',
    },
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Група продуктів',
      key: 'offerGroupId',
      render: (_: any, record: any) => (
        <Select
          showSearch
          placeholder="Оберіть групу"
          value={record.offerGroupId || undefined}
          onChange={(value) => handleGroupChange(record.offerId, value)}
          style={{ width: 200 }}
          filterOption={(input, option) =>
            ((option?.label ?? '')).toLowerCase().includes(input.toLowerCase())
          }
          options={groups.map((group) => ({
            label: group.name, // Виводимо назву групи
            value: group.id,   // Використовуємо id для збереження
          }))}
        />
      ),
    },
    {
      title: 'Дозволені джерела',
      key: 'allowedSourceId',
      render: (_: any, record: any) => (
        <Select
          mode="multiple" // Підтримка множинного вибору
          placeholder="Оберіть джерела"
          value={record.allowedSourceId || []} // Масив ідентифікаторів джерел
          onChange={(value) => handleSourceChange(record.offerId, value)}
          style={{ width: '60%', minWidth: 150 }}
          filterOption={(input, option) =>
            ((option?.label ?? '')).toLowerCase().includes(input.toLowerCase())
          }
          options={sources.map((source) => ({
            label: source.name, // Виводимо назву джерела
            value: source.sourceId,   // Використовуємо id для збереження
          }))}
        />
      ),
    },
    {
      title: 'Капа',
      key: 'capCount',
      render: (_: any, record: any) => (
        <Input placeholder='Капа'
          value={record.capCount || null}
          onChange={(e) => handleCapCountChange(record.offerId, e.target.value)}
        />
      ),
    },
    {
      title: 'Дата початку пролиття',
      key: 'capDate',
      render: (_: any, record: any) => (
        <DatePicker placeholder='Дата'
          value={record.capDate ? dayjs(record.capDate) : null}
          onChange={(date) => handleCapDateChange(record.offerId, date ? date.toDate() : null)}
          allowClear
        />
      ),
    },
    {
      title: 'Коментар',
      key: 'comment',
      render: (_: any, record: any) => (
        <Input placeholder='Коментар'
          value={record.comment || null}
          onChange={(e) => handleCommentChange(record.offerId, e.target.value)}
        />
      ),
    },
    {
      title: 'Тільки редепи',
      key: 'onlyRedep',
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.onlyRedep}
          onChange={(e) => handleRedepCheckboxChange(record.offerId, e.target.checked)}
        />
      ),
    },
    {
      title: 'Активний',
      key: 'active',
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.isActive} // Використовуємо поле isActive з самого оффера
          onChange={(e) => handleCheckboxChange(record.offerId, e.target.checked)}
        />
      ),
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: '16px', flexWrap: 'wrap' }}>
        <Button type="primary" onClick={handleSave} disabled={isSaveDisabled}>
          Зберегти
        </Button>
        <Button onClick={handleRefresh}>Оновити</Button>

        <Input
          placeholder="Фільтр по ID"
          value={filterOfferId}
          onChange={(e) => setFilterOfferId(e.target.value)}
          style={{ width: 200 }}
        />

        <Input
          placeholder="Фільтр по назві"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          style={{ width: 200 }}
        />

        <Select
          showSearch
          allowClear
          placeholder="Фільтр по групі продуктів"
          style={{ width: 200 }}
          value={filterGroupId}
          onChange={(value) => setFilterGroupId(value)}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={groups.map((g) => ({ label: g.name, value: g.id }))}
        />

        <Select
          mode="multiple"
          showSearch
          allowClear
          placeholder="Фільтр по дозволеним джерелам"
          style={{ width: 250 }}
          value={filterAllowedSources}
          onChange={(value) => setFilterAllowedSources(value)}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={sources.map((src) => ({ label: src.name, value: src.sourceId }))}
        />

        <Select
          allowClear
          placeholder="Фільтр по активності"
          style={{ width: 200 }}
          value={filterIsActive === undefined ? undefined : filterIsActive ? 'active' : 'inactive'}
          onChange={(value) => {
            if (value === 'active') {
              setFilterIsActive(true);
            } else if (value === 'inactive') {
              setFilterIsActive(false);
            } else {
              setFilterIsActive(undefined);
            }
          }}
          options={[
            { label: 'Активні', value: 'active' },
            { label: 'Неактивні', value: 'inactive' },
          ]}
        />
        <Select
          showSearch
          allowClear
          placeholder="Фільтр по країні"
          style={{ width: 200 }}
          value={filterCountry}
          onChange={(value) => setFilterCountry(value)}
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'Без країни', value: '' },
            ...countries.map((c) => ({ label: c, value: c })),
          ]}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredOffers}
        loading={loading}
        rowKey="offerId"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
            setCurrentPage(1); // Повертаємося на першу сторінку при зміні розміру
          },
          onChange: (page) => setCurrentPage(page),
        }}
        style={{ marginTop: '16px' }}
      />
    </div>
  );
};

export default OffersTable;
