// CapTable.tsx
import React, { useEffect, useState } from 'react';
import { Table, Button, message, Spin } from 'antd';
import axios from '../api/axiosInstance';
import { ColumnsType } from 'antd/es/table';
import eventSourceInstance from '../api/eventSourceInstance';

interface CapData {
  name: string;
  capCount: number;
  leftDepCount: number;
  redepDebt: number;
  updatedAt: string; // Якщо дата у форматі ISO-строки
}

interface AllCapResponse {
  data?: CapData[];
  message?: string;
}

const CapTable: React.FC = () => {
  const [data, setData] = useState<CapData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchCapData = async () => {
    setLoading(true);
    try {
      const response = await axios.get<AllCapResponse>('/api/cap/getAllCap');
      if (response.data.data) {
        setData(response.data.data);
      } else if (response.data.message) {
        message.error(response.data.message);
      } else {
        message.error('Невідома помилка при отриманні даних');
      }
    } catch (error) {
      message.error('Помилка при отриманні даних');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateClick = () => {
    eventSourceInstance.closeAll();

    // Використовуємо відносний шлях
    const es = eventSourceInstance.create('/api/cap/updateAllCap');

    es.onmessage = (e: MessageEvent) => {
      const messageData = JSON.parse(e.data);

      if (messageData.event === 'data') {
        const newCap = messageData.data;
        setData((prevData) => {
          const index = prevData.findIndex((item) => item.name === newCap.name);
          if (index !== -1) {
            const newData = [...prevData];
            newData[index] = newCap;
            return newData;
          } else {
            return [...prevData, newCap];
          }
        });
      } else if (messageData.event === 'error') {
        message.error(messageData.data);
        es.close();
      } else if (messageData.event === 'end') {
        message.success("Успішно оновлено!");
        es.close();
      }
    };


    es.onerror = (error: Event) => {
      message.error(`Помилка SSE: ${error.type}`);
      es.close();
    };
  };

  useEffect(() => {
    fetchCapData();
  }, []);

  const columns: ColumnsType<CapData> = [
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Кількість Капи',
      dataIndex: 'capCount',
      key: 'capCount',
      sorter: (a, b) => a.capCount - b.capCount,
    },
    {
      title: 'Залишилось Депів',
      dataIndex: 'leftDepCount',
      key: 'leftDepCount',
      sorter: (a, b) => a.leftDepCount - b.leftDepCount,
    },
    {
      title: 'Борг по Редепам',
      dataIndex: 'redepDebt',
      key: 'redepDebt',
      sorter: (a, b) => a.redepDebt - b.redepDebt,
    },
    {
      title: 'Оновлено',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => new Date(text).toLocaleString(),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          handleUpdateClick();
        }}
        style={{ marginBottom: 16 }}
      >
        Оновити Таблицю
      </Button>
      {loading ? (
        <Spin tip="Завантаження...">
          <Table columns={columns} dataSource={[]} rowKey="name" />
        </Spin>
      ) : (
        <Table 
        columns={columns}
        dataSource={data} 
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
            setCurrentPage(1);
          },
          onChange: (page) => setCurrentPage(page),
        }}
        rowKey="name" />
      )}
    </div>
  );
};

export default CapTable;
