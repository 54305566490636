import React, { useState, useEffect } from 'react';
import { Input, Button, Card, Typography, Space } from 'antd';
import axios from '../api/axiosInstance';

const { Title, Paragraph } = Typography;

const CampaignPage: React.FC = () => {
  const [alias, setAlias] = useState<string>('');
  const [responseData, setResponseData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'DirectCRM Пошук кампанії';
  }, []);

  // Функція для відправки POST-запиту
  const handleSearch = async () => {
    setLoading(true);
    setResponseData(null); // Очищаємо попередній результат
    try {
      const response = await axios.post('/api/campaign', { alias });
      setResponseData(response.data);
    } catch (error: any) {
      if (error.response) {
        // Сервер повернув код помилки (4xx, 5xx)
        setResponseData({ error: `Server error: ${error.response.status}` });
      } else {
        // Інші помилки (відсутній інтернет, CORS, тощо)
        setResponseData({ error: error.message || 'Невідома помилка' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title level={3} style={{ marginBottom: '16px' }}>
        Пошук кампанії по alias
      </Title>

      <Space direction="vertical" style={{ width: '300px' }}>
        <Input
          placeholder="Введіть alias"
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
          onPressEnter={handleSearch} // Пошук при натисканні Enter
          style={{ width: ' %' }}
        />

        <Button type="primary" onClick={handleSearch} loading={loading}>
          Шукати
        </Button>
      </Space>

      {responseData && (
        <Card style={{ marginTop: '20px', backgroundColor: '#fafafa' }}>
          {typeof responseData === 'object' ? (
            <Paragraph code>
              {JSON.stringify(responseData, null, 2)}
            </Paragraph>
          ) : (
            <Paragraph>{String(responseData)}</Paragraph>
          )}
        </Card>
      )}
    </>
  );
};

export default CampaignPage;
