import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { notification } from 'antd';
import { AuthProvider } from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import UsersPage from './pages/UsersPage';
import SourcesPage from './pages/SourcesPage';
import PrivateRoute from './routes/PrivateRoute';
import MainLayout from './layouts/MainLayout';
import OffersPage from './pages/OffersPage';
import ProductGroupsPage from './pages/ProductGroupsPage';
import BundlesPage from './pages/BundlesPage';
import TeamsPage from './pages/TeamsPage';
import LinksPage from './pages/LinksPage';
import UniqueLeadPage from './pages/UniqueLeadPage';
import CapsPage from './pages/CapsPage';
import SettingsPage from './pages/SettingsPage';
import LogsPage from './pages/LogsPage';
import CampaignPage from './pages/CampaignPage';



const App: React.FC = () => {
  notification.config({
    duration: 15,
  });

  return (
    <AuthProvider>
      <Router>
      <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/users"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <UsersPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/caps"
            element={
              <PrivateRoute>
                <MainLayout>
                  <CapsPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/campaign"
            element={
              <PrivateRoute>
                <MainLayout>
                  <CampaignPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sources"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <SourcesPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/products"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <OffersPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/product-groups"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <ProductGroupsPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/bundles"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <BundlesPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/teams"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <TeamsPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/logs"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <LogsPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute requiredRole="admin">
                <MainLayout>
                  <SettingsPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout>
                  <LinksPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/unique-leads"
            element={
              <PrivateRoute>
                <MainLayout>
                  <UniqueLeadPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
           {/* Додайте інші маршрути*/}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;